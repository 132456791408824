import React from "react";
import "../App.css";
import { Link, useNavigate } from "react-router-dom"; 
import { setLoggedIn } from "../Actions";
import { connect } from "react-redux";

function Menus(props) {
  const navigate = useNavigate(); 

  const logout = () => {
    localStorage.clear("token");
    localStorage.clear("user_id");
    props.setLoggedIn();
    navigate("/"); 
  };

  return (
    <>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">About</Link>
            </li>
            <li>
              <Link to="/read">Read</Link>
            </li>
            {props.loggedIn ? (
              <li>
                <Link to="/" onClick={logout}>
                  Logout
                </Link>
              </li>
            ) : (
              <li>
                <Link to="/login">Login</Link>
              </li>
            )}
          </ul>
        </nav>
        {props.loggedIn ? (
          <div id="loggedInMenu">
            <ul>
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li>
                <Link to="/profile">My Profile</Link>
              </li>
              <li>
                <Link to="/theforum">The Forum</Link>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    first_name: state.first_name,
    last_name: state.last_name,
    loggedIn: state.loggedIn,
  };
};

export default connect(mapStateToProps, { setLoggedIn })(Menus);
